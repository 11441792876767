import address from "../../services/address"
import axios from "axios"

const axiosApi = axios.create({
  baseURL: address,
})

export async function getEmailBody(config = {}) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.get(`/api/subscriber/getAllEmailBodies`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      ...config,
    })

    return data
  }
}

export async function updateAskBody(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.put(
      `/api/subscriber/updateAskEmailBody`,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function updateInviteBody(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.put(
      `/api/subscriber/updateInviteEmailBody`,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function updateReminderBody(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.put(
      `/api/subscriber/updateReminderEmailBody`,
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function setEmailBody(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/subscriber/setAllEmailBodies",
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function sendAskEmail(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/client/askClientForTestmonials",
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

export async function sendBulkEmail(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    //   const formData = new FormData();
    // formData.append('askEmailBody', formdata.askEmailBody);
    // formData.append('file', formdata.file);
    const { data } = await axiosApi.post(
      "/api/client/askClientForTestmonials/bulk-invite",
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}

export async function ClientFields(formdata) {
  const token = localStorage.getItem("token")
  if (token) {
    const { data } = await axiosApi.post(
      "/api/subscriber/setting/client-custom-fields",
      formdata,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return data
  }
}
export async function getClientFieldList() {
  const token = localStorage.getItem("token")
  if (!token) return Promise.reject("No token found")
  const { data } = await axiosApi.get(
    "/api/subscriber/setting/client-custom-fields",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return data
}

export async function getQrClientFieldList(companyId, campaignId) {
  const { data } = await axiosApi.get(
    `/api/${companyId}/${campaignId}/setting/client-fields`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  )

  return data
}
