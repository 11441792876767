import { useQueryClient } from "@tanstack/react-query"
import MahaloBtn from "components/Shared/Buttons/MahaloBtn"
import { useClearData } from "components/VerticalLayout/ImageContextProvider"
import { queryClient } from "index"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { switchCompany } from "services/admin/Subscriber/subscriber"
import { switchCompanyAction, switchUserAction } from "store/actions"

function BacktoAgency() {
  const [isSwitching, setIsSwitching] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clearData = useClearData()
  const token = useSelector(state => state.Auth.token)

  return (
    <>
      {!!localStorage.getItem("superAgency") && (
        <MahaloBtn
          preloader={isSwitching}
          handleSubmit={async () => {
            setIsSwitching(true)
            await switchCompany(localStorage.getItem("superAgency"))
              .then(res => {
                if (res?.data) {
                  clearData()
                  queryClient.clear()
                  localStorage.removeItem("superAgency")
                  dispatch(switchCompanyAction({ token: token }))
                  navigate("/user/agency")
                } else {
                  toast.info(res?.message ?? "Failed to switch company")
                  if (res?.message) {
                    localStorage.removeItem("superAgency")
                  }
                  console.warn("Failed to switch company", res)
                }
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                setIsSwitching(false)
              })
          }}
          rounded={"rounded-3"}
          onClick={() => {}}
          style={{
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="backToAgencyBtn"
          // variant="mahalo-primary"
        >
          <div className="d-flex gap-2 justify-content-center align-items-center">
            <i className="fas fa-arrow-left" />
            <span className="backToAgencyText d-none d-md-block">
              Back to Agency
            </span>
          </div>
        </MahaloBtn>
      )}
    </>
  )
}

export default BacktoAgency
