const LogoutPage = lazy(() => import("../pages/Authentication/Logout"))
const RegisterPage = lazy(() => import("../pages/Authentication/Register"))
const PaymentDetail = lazy(() => import("pages/Subscription/paymentDetail"))
const Page404 = lazy(() => import("../Modules/AuthKit/pages/Page404"))

const EmailVerify = lazy(() => import("pages/Subscription/emailVerify"))

//Subscription
const Channels = lazy(() => import("pages/Guidelines/channels"))
const Guidelines = lazy(() => import("pages/Guidelines/guidelines"))

//Public

//Transfer Ownership
import TransferOwnershipPages from "../pages/Public/TransferOwnership/pages/routes"

//External Approve View
const ExternalApproveView = lazy(() =>
  import("../pages/Public/ExternalApproveView")
)

// Authentication related pages
const ForgetPwd = lazy(() => import("../pages/Authentication/ResetPassword"))
const ChangePwd = lazy(() => import("../pages/Authentication/ChangePassword"))
const SubuserPwd = lazy(() => import("../pages/Authentication/SubuserPassword"))

//Ask Response
const AskResponse = lazy(() => import("../pages/Utility/askResponse"))
//Testimonials
//Testimonial Record
const InviteTestimonials = lazy(() =>
  import("pages/Testimonials/pages/InviteTestimonials")
)
const Testimonial = lazy(() => import("pages/Testimonials"))

//Pages
const PagesMaintenance = lazy(() =>
  import("../pages/Utility/pages-maintenance")
)
const PagesComingsoon = lazy(() => import("../pages/Utility/pages-comingsoon"))
const Pages404 = lazy(() => import("../pages/Utility/pages-404"))
const Pages500 = lazy(() => import("../pages/Utility/pages-500"))

const Test = lazy(() => import("pages/Dashboard/TestPage/Test"))

const PublicRoutes = [
  // Test Page
  { path: "/test", element: <Test /> },
  { path: "/subuser/password", element: <SubuserPwd /> },
  { path: "/forgot-password", element: <ForgetPwd /> },
  { path: "/change-password", element: <ChangePwd /> },
  { path: "/pages-maintenance", element: <PagesMaintenance /> },
  { path: "/pages-comingsoon", element: <PagesComingsoon /> },
  { path: "/pages-404", element: <Pages404 /> },
  { path: "/pages-500", element: <Pages500 /> },
  //AskResponse
  { path: "/askresponse", element: <AskResponse /> },
  //Subscription
  { path: "/channels", element: <Channels /> },
  { path: "/guidelines", element: <Guidelines /> },
  // Testimonials
  //Record
  { path: "/testmonial-tips", element: <InviteTestimonials /> },
  { path: "/testimonials-record", element: <Testimonial /> },
  {
    path: "/testimonials-record/:campaignId",
    element: <Testimonial />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/404",
    element: <Page404 />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/paymentdetail",
    element: <PaymentDetail />,
  },
  { path: "/verifyemail", element: <EmailVerify /> },
  ...TransferOwnershipPages,
]

export default PublicRoutes

//imports
import React, { lazy } from "react"
